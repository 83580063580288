<template>
  <div class="designer-concern-pannel designer-likes-pannel">
    <div class="common-title">我的下载</div>
    <div class="content">
      <el-table
        v-loading="pageLoading"
        :data="waterfallList"
        style="width: 100%"
        height="calc(100% - 40px)"
      >
        <el-table-column type="index" label="编号" width="50" align="center">
        </el-table-column>
        <el-table-column prop="name" label="作品名称" align="center">
        </el-table-column>
        <el-table-column label="作品大小" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.size < 1024">{{ scope.row.size }}KB</span>
            <span v-else>{{ (scope.row.size / 1024).toFixed(2) }}MB</span>
          </template>
        </el-table-column>

        <el-table-column prop="updateTime" label="下载日期" align="center">
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="作者"
          align="center"
        >
          <template slot-scope="scope">
            <span class="user-name">{{ scope.row.nickname }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageParams.total"
          :current-page="pageParams.currentPage"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { downPic } from "@/libs/tools";
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      waterfallList: [],
      pageParams: {
        pageSize: 10,
        pageNum: 0,
        total: 0,
        currentPage: 0,
      },
      pageLoading: false,
      isEmpty: false,
      maxCols: 5,
    };
  },
  created() {
    this.getData(true);
  },
  mounted() {},
  methods: {
    getData(flag) {
      // console.log("loadmore");
      if (flag) {
        this.waterfallList = [];
        this.pageParams = {
          pageSize: 10,
          pageNum: 0,
          total: 0,
          currentPage: 0,
        };
      }
      this.pageLoading = true;
      // this.pageParams.pageNum++;
      let params = Object.assign({}, this.pageParams);
      this.$http
        .get("/resource-info/download", { params })
        .then((res) => {
          // console.log(res);
          if (res.code == this.SUCCESS_CODE) {
            let data = res.data;
            this.pageParams.total = data.total;
            this.pageParams.currentPage = data.current;
            if (data.records.length) {
              this.waterfallList = data.records;
            }
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.waterfallList = [];
          this.isEmpty = true;
          this.pageLoading = false;
        });
    },
    // 保存下载记录
    saveDownloadRecords(item) {
      this.pageLoading = true;
      let ids = item.id;
      this.$http
        .post("/download/download", null, { params: { ids } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            downPic(item.obsUrl, item.name);
          } else {
            this.$message.error(res.msg);
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.pageLoading = false;
        });
    },
    downloadPic(item) {
      this.saveDownloadRecords(item);
    },
    // 分页
    handlePageChange(current) {
      console.log(current);
      this.pageParams.pageNum = current;
      this.getData();
    },
  },
};
</script>